.sc {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    
    &_player {
        background-image: url(../../assets/images/galaga.png);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 47%;
        left: 7%;
        height: 3rem;
        width: 3rem;
    }

    &_aliens {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        margin-right: 2.5rem;
    }
    
    &_alien-row-1 {
        height: 12rem;
        margin-right: 1rem;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
    }
    &_alien-row-2 {
        margin-right: .5rem;
        display: flex;
        flex-direction: column;
    }

    &_alien-row-3 {
        display: flex;
        flex-direction: column;
        height: 32rem;
    }



    &_alien-1 {
        background-image: url(../../assets/images/galagaalien1.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 35.5%;
        // left: 70%;
        height: 3rem;
        width: 3rem;

    }

    &_alien-2 {
        background-image: url(../../assets/images/galagaalien1.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 48%;
        // left: 70%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-3 {
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 14%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-4{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 15%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-5{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 16%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-6{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 17%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-7{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 18%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-8{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 19%;
        left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-9{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 20%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-10{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // top: 21%;
        // left: 75%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-11{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 24.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-12{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 23.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-13{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 22.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-14{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 21.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-15{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 20.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-16{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 19.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-17{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 18.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }
    &_alien-18{
        background-image: url(../../assets/images/galagaalien2.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 17.25%;
        // left: 80%;
        height: 3rem;
        width: 3rem;
    }

    &_alien-19{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-20{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-21{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-22{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-23{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-24{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-25{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }
    &_alien-26{
        background-image: url(../../assets/images/galagaalien3.png);
        background-repeat: no-repeat;
        background-size: contain;
        // position: relative;
        // bottom: 64%;
        // left: 85%;
        height: 4rem;
        width: 4rem;
    }

}