@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;


.footer {
    height: 16rem;
    background-color: $portfolio-black;
    position: relative;
    border-top: 4px solid $portfolio-green;

    &_up {
        height: 3rem;
        width: 2.5rem;
        background-color: $portfolio-red;
        position: absolute;
        top: -1.5rem;
        left: 48.5%;
        background-image: url(../../assets/icons/double-up-svgrepo-com.svg);
        background-repeat: no-repeat;
        background-size: 1.5rem;
        background-position-x: center;
        background-position-y: .4rem;
    }

    &_social {
        display: flex;
        justify-content: space-between;
        margin: auto;
        width: 30%;
        padding-top: 4rem;

        @include tablet {
            width: 50%;
        }

        @include mobile {
            width: 70%;
        }

        &_linkedin{
            height: 3rem;
            width: 3rem;
            background-color: $portfolio-green;
            background-image: url(../../assets/icons/linkedin-svgrepo-com.svg);
            background-repeat: no-repeat;
            background-size: 2.5rem;
            background-position-x: center;
            background-position-y: center;
            margin-right: 1rem;
        }

        &_github{
            height: 3rem;
            width: 3rem;
            background-color: $portfolio-green;
            background-image: url(../../assets/icons/github-svgrepo-com.svg);
            background-repeat: no-repeat;
            background-size: 1.75rem;
            background-position-x: center;
            background-position-y: center;
            margin-right: 1rem;
        }

        &_instagram{
            height: 3rem;
            width: 3rem;
            background-color: $portfolio-green;
            background-image: url(../../assets/icons/instagram-svgrepo-com.svg);
            background-repeat: no-repeat;
            background-size: 1.75rem;
            background-position-x: center;
            background-position-y: center;
            margin-right: 1rem;
        }

        &_facebook{
            height: 3rem;
            width: 3rem;
            background-color: $portfolio-green;
            background-image: url(../../assets/icons/facebook-f-svgrepo-com.svg);
            background-repeat: no-repeat;
            background-size: 1.75rem;
            background-position-x: center;
            background-position-y: center;
        }
    }

    &_name {
        color: white;
        width: 100%;
        text-align: center;
        margin: auto;
        padding-top: 2.5rem;
        font-size: 1.25rem;
        
       &_copyright{
        color: $portfolio-red;
       } 
    }
}