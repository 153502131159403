@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.portfolio-container {
    background-color: white;
    margin: auto;
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;

}

.portfolio {

    &_title {
        display: flex;
        justify-content: center;
        padding-bottom: .5rem;
    }
}

.project{
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.35rem;

    @include tablet {
        justify-content: center;
    }

    &_cs {
        height: 21rem;
        width: 21rem;
        margin: 1rem;
        border: 1px solid black;
        background-image: url(../../assets/images/Coffeeshop.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        transition: background-color 0.3s ease;
      
        &_cs:hover {
          background-color: black 
        }
      }
      

    &_ts{
        height: 21rem;
        width: 21rem;
        margin: 1rem;
        border: 1px solid black;
        background-image: url(../../assets/images/travelsite.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &_bf{
        height: 21rem;
        width: 21rem;
        margin: 1rem;
        border: 1px solid black;
        background-image: url(../../assets/images/brainflix.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &_bs{
        height: 21rem;
        width: 21rem;
        margin: 1rem;
        border: 1px solid black;
       background-image: url(../../assets/images/bandsite.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &_is{
        height: 21rem;
        width: 21rem;
        margin: 1rem;
        border: 1px solid black;
        background-image: url(../../assets/images/Instock.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &_tag{
        height: 21rem;
        width: 21rem;
        margin: 1rem;
        border: 1px solid black;
        background-image: url(../../assets/images/tag.png);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

    }
}
