@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.landing {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include mobile {
        left: 54%;
    }
  

    &_title{
        color: white;
        font-size: 2rem;
        font-weight: 400;
        width: 28.1rem;

        @include tablet {
            font-size: 1.7rem;
            width: 24.1rem;
        }

        @include mobile {
            font-size: 1.7rem;
            width: 19.1rem;
        }

        &_name {
            font-weight: 400;
        }
    }

    &_buttons {
        display: flex;
        justify-content: center;
        width: 28rem;
        margin-top: 2rem;

        @include tablet {
            flex-direction: column;
            width: 24rem;
            margin-right: 0;
        }

        @include mobile {
            width: 18rem;
        }

        &-1 {
            display: none;
            border: 2px solid $portfolio-red;
            border-radius: .25rem;
            background: $portfolio-red;
            padding: .5rem;
            font-size: 1rem;
            cursor: pointer;
            font-weight: bold;
            transition: .5s;
            filter: drop-shadow(0 0 .5rem $portfolio-red) contrast(2) brightness(1);

            @include tablet {
                margin-bottom: 1rem;
            }

        }     

        &-2 {
            border: 1px solid $portfolio-green;
            border-radius: .25rem;
            background: $portfolio-green;
            padding: .5rem;
            font-size: 1rem;
            cursor: pointer;
            font-weight: bold;
            transition: .5s;
            filter: drop-shadow(0 0 .5rem $portfolio-green) contrast(2) brightness(1);

        }     
    }
}

.landing_buttons-1:hover {
    color: black;
    background-color: $portfolio-red;
    filter: drop-shadow(0 0 1rem $portfolio-red) contrast(2) brightness(1);
  }

  .landing_buttons-2:hover {
    color: black;
    background-color: $portfolio-green;
    filter: drop-shadow(0 0 1rem $portfolio-green) contrast(2) brightness(1);
  }
