@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.contact-container {
    
    padding-top: 3rem;
    margin: auto;
    height: 100%;
    padding-bottom: 4rem;

}

.contact {

    &_title {
        display: flex;
        justify-content: center;
        padding-bottom: .5rem;
    }
}