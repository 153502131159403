@use './styles/partials/variables' as *;
@use './styles/partials/mixins' as *;

html,body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
  }

body {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  background-color: $portfolio-light-gray;
}