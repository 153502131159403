@use '../../styles/partials/mixins' as *;

.background {
    width: 100%;
    height: 100vh;
    background-color: #000;
    background-image: radial-gradient(circle at top left, rgba(102, 51, 153, .25), transparent);


    &_canvas {
        position: fixed;
        width: 100%;
        height: 100%;
    }
    
}