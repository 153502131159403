@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.about-container {
    width: 80%;
    margin: auto;
    height: 100%;
    margin-bottom: 3rem;

    @include tablet {
        width: 80%;
    }
}

.about {

    &_title {
        display: flex;
        justify-content: center;
        padding-bottom: .5rem;
        margin-top: 4rem;
    }

 }

.skill {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 4rem;

    @include tablet {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &_card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 20%;
        text-align: center;

        @include tablet {
            justify-content: flex-start;
            flex: 0 50%;
            margin-bottom: 2.5rem;
        }

        @include mobile {
            flex: 0 100%;
            margin-bottom: 2.5rem;
        }

        &_desc {
            margin: 0;
        }

        &_responsive{
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            background-color: $portfolio-blue;
            background-image: url(../../assets/icons/responsive-svgrepo-com.svg);
            background-size: 4rem;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
        }

        &_speed{
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            background-color: $portfolio-blue;
            background-image: url(../../assets/icons/speed-o-meter-svgrepo-com.svg);
            background-size: 4rem;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
        }

        &_intuitive{
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            background-color: $portfolio-blue;
            background-image: url(../../assets/icons/light-bulb-svgrepo-com.svg);
            background-size: 4rem;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
        }

        &_dynamic{
            height: 6rem;
            width: 6rem;
            border-radius: 50%;
            background-color: $portfolio-blue;
            background-image: url(../../assets/icons/rocket-svgrepo-com.svg);
            background-size: 4rem;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: center;
        }
    }
}

.profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7rem;

    @include tablet {
        margin-top: 3rem;
        margin-bottom: 3rem;
        flex-direction: column;
    }

    &_card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        width: 40%;

        @include tablet {
            width: 100%;
        }


        &_person {
            height: 15rem;
            width: 15rem;
            border-radius: 50%;
            background-image: url(../../assets/images/E18D4643-A680-42A3-B39E-C4B331957A66.JPG);
            background-size: cover;
            background-repeat: no-repeat;
            background-position-x: center;
        }

        &_button {
            background: none;
            border: none;
            padding: 0;
            
            color: #069;
            cursor: pointer;
        }
    }

    &_skills{
        width: 50%;

        @include tablet {
            width: 100%;
            margin-top: 3rem;
        }

        &_card{
            background-color: $portfolio-gray;
            height: 2rem;
            text-align: center;
            display: flex;
            margin-top: 1rem;

            &_background {
                background-color: $portfolio-dark-blue;
                width: 70%;

                &-60{
                    width: 40%;
                    background-color: $portfolio-dark-blue;
                }
                &-70{
                    width: 50%;
                    background-color: $portfolio-dark-blue;
                }

                &-75{
                    width: 55%;
                    background-color: $portfolio-dark-blue;
                }

                &-80{
                    width: 60%;
                    background-color: $portfolio-dark-blue;

                }
                &-85{
                    width: 65%;
                    background-color: $portfolio-dark-blue;

                }
            }
            &_title{
                background-color: $portfolio-blue;
                width: 30%;
                font-weight: 600;
                color: white;
                padding-top: 0.35rem;

                @include tablet {
                    min-width: 25%;
                    font-size: 0.9rem;
                }

                @include mobile {
                    min-width: 29%;
                    font-size: 0.9rem;
                }
                
            }
            &_percentage{
                margin: 0;
                width: 10%;
                padding: 0.35rem 0.5rem;                
                text-align: right;

                &-60{
                    margin: 0;
                    padding-top: .35rem;
                    width: 40%;
                    padding-right: 0.5rem;
                    text-align: right;
                }

                &-70{
                    margin: 0;
                    padding-top: .35rem;
                    width: 30%;
                    padding-right: 0.5rem;
                    text-align: right;
                    }

                &-75{
                    margin: 0;
                    padding-top: .35rem;
                    width: 25%;
                    padding-right: 0.5rem;
                    text-align: right;
                }

                &-80{
                    margin: 0;
                    padding-top: .35rem;
                    width: 20%;
                    padding-right: 0.5rem;
                    text-align: right;
                }

                &-85{
                    margin: 0;
                    padding-top: .35rem;
                    width: 15%;
                    padding-right: 0.5rem;
                    text-align: right;
                    }
            }
        }
    }
}