@use '../../styles/partials/mixins' as *;
@import '../../styles/partials/variables';

.text-input {
  display: flex;
  flex-direction: column;
  gap: .25rem;
  text-transform: capitalize;
  width: 100%;

  &__heading {
    font-size: .8125rem;
    font-weight: 600;
    color: black;
  }

  &__input {
    height: 2.25rem;
    padding: 0 .75rem;
    border-radius: .25rem;
    border: 1px solid gray;
    font-family: 'Raleway', sans-serif;

    &.error {
      outline: 1px solid red;
    }

    &::placeholder {
      color: rgb(86, 86, 86);
      font-size: 0.8125rem;
    }

    &:focus-visible {
      outline: 1px solid $portfolio-blue;
    }

    &--large {
      @extend .text-input__input;
      resize: none;
      height: 7.5rem;
      padding-top: .5rem;
      padding-bottom: .5rem;
      text-transform: none;
      font-family: 'Raleway', sans-serif;
        }

    &--search {
      @extend .text-input__input;
      padding-right: 2.5rem;
      text-transform: none;

    
    }

    &--contact {
      @extend .text-input__input;
      text-transform: none;
    }
  }

  &__error {
    display: flex;
    gap: .25rem;

    &-icon {
      width: .75rem;
    }

    &-text {
      font-size: .8125rem;
      color: red;
    }
  }
}