// colors
$portfolio-black: #000000;
$portfolio-red: #E94F64;
$portfolio-green: #52D273;
$portfolio-dark-green: darkgreen;
$portfolio-blue: #46BEDE;
$portfolio-dark-blue: #3aaac9;
$portfolio-yellow: #E5C454;
$portfolio-gray: #D6D8DE;
$portfolio-light-gray: #edeef1;


// breakpoints 
$mobile-breakpoint: 420px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
