@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.header-container {
    background-color: $portfolio-black;
    border-bottom: 3px solid $portfolio-green;
    height: 4rem;
    display: flex;
    align-items: center;
}



.nav {
    display: flex;
    width: 80%;
    margin: auto;

    @include mobile {
        margin: 0;
    }


    &_list {
        display: flex;
        padding: 0;

        &_link{
            color: white;
            list-style: none;
            margin: 0 .5rem;
            font-size: .8rem;
            cursor: pointer;

            &-home {
                color: white;
                text-decoration: none;
            }

            @include tablet {
                font-size: .9rem;
            }

            @include desktop {
                font-size: 1rem;
            }
        }
    }
}