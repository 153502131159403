
@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.signup-form {
    width: 40%;
    margin: auto;

    @include tablet {
      width: 80%;
      margin: auto;
    }


  &__heading {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.75rem;
    margin-bottom: .5rem;
    color: black;

  
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  



    &--first {
      @extend .signup-form__details;
    }

   
  }

  &__submit-button {
      background-color: $portfolio-dark-blue;
      font-size: .9rem ;
      height: 2rem;
      width: 7rem;
      color: white;
      border: none;
      border-radius: .15rem;
      align-self: center;
      cursor: pointer;


  }
}