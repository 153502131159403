@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.game {
    position: absolute;
    top: 32%;
    left: 50%;
    transform: translate(-50%, -50%);

    &_title{
        color: $portfolio-gray;
        font-size: 5rem;
        font-family: 'Press Start 2P', cursive;
        text-shadow: 4px 4px darkred;

        &_name {
            font-weight: 400;
        }
    }

    &_buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 34.5rem;
        margin-top: 6rem;


        &-1 {
            border: 2px solid $portfolio-red;
            border-radius: .25rem;
            background: $portfolio-red;
            width: 10rem;
            padding: .5rem;
            font-size: 1rem;
            cursor: pointer;
            font-weight: bold;
            transition: .5s;
            filter: drop-shadow(0 0 .5rem $portfolio-red) contrast(2) brightness(1);

        }        

        &_link{
            color: $portfolio-green;
        }
    }
}

.game_buttons-1:hover {
    color: black;
    background-color: $portfolio-red;
    filter: drop-shadow(0 0 2rem $portfolio-red) contrast(3) brightness(1);
  }

